.App-logo {
  height: 40vmin;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

* {
  color: #231311;
}

a {
  color: #16476B;
}

body {
}

input[type="text"] {
  background-color: #F6F5F9
}

.vote-icon {
  padding: 2px;
}

.vote-icon:hover {
  background-color: #eee;
  text-align: center
}

.posts td {
  padding: 24px 0;
}

.App-link {
  color: #09d3ac;
}
